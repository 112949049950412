'use client'
import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import LoginImg from '#/public/media/layout/login.jpg'

// components
import Login from './Login/Login';
import Number from './Number/Number';
import Token from './Token/Token';
import Register from './Register/Register';
import PassReset from './ResetPassword/ResetPassword';
import PassRequested from './ResetPassword/PassRequested';
import PassRecovered from './ResetPassword/PassRecovered';

import { Customer, TipoCompra } from './Account';
// import ModalGeo from './modal-geo';

// utilities
import { xclose } from '~/helpers/svg';
import Image from 'next/image';

const ModalCustomer = props => (
  <section className="modal--customer">
    {props.children}
  </section>
);

const ModalTipoCompra = props => (
  <section className="modal--tipo-compra">
    {props.children}
  </section>
);

const ModalUser = props => (
  <section className="modal--user">
    <picture id="login-pic">
      <Image src={LoginImg} alt="Claro" />
    </picture>
    {props.children}
  </section>
);

const ModalValidateNumber = props => (
  <section className="modal--validate-number">
    {props.children}
  </section>
);

const ModalValidateToken = props => (
  <section className="modal--validate-token">
    {props.children}
  </section>
);

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalState: 'onLogin',
      geo: true,
      modalType: null,
      modalEmail: null,
    };

    // Bind
    this.modalType = this.modalType.bind(this);
    this.modalEmail = this.modalEmail.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  closeModals() {
    this.setState({ modalType: null });
    this.setState({ modalEmail: null });
    $('.modal--customer, .modal--user, .modal--geo, .modal--tipo-compra, .modal--validate-number, .modal--validate-token').removeClass('active');
    $('.modal').fadeOut();
  }

  geoTtigger() {
    this.setState({ geo: true });
  }

  modalType(type) {
    this.setState({ modalType: type });
  }

  modalEmail(email) {
    this.setState({ modalEmail: email });
  }

  render() {
    let modal;

    switch (this.state.modalType) {
      case 'onRegister':
        modal = (<Register modalType={this.modalType} closeModals={this.closeModals} />);
        break;
      case 'onPassReset':
        modal = (<PassReset modalType={this.modalType} modalEmail={this.modalEmail} />);
        break;
      case 'onPassRequested':
        modal = (<PassRequested modalType={this.modalType} />);
        break;
      case 'onPassRecovered':
        modal = (<PassRecovered modalType={this.modalType} modalEmail={this.state.modalEmail} />);
        break;
      default:
        modal = (<Login modalType={this.modalType} closeModals={this.closeModals} />);
        break;
    }

    return (
      <div className="modal">
        <div className="modal--container">
          <div className="modal--bg" onClick={this.closeModals} />
          <a className="modal--xclose" onClick={this.closeModals}>{xclose}</a>
          <ModalUser>
            {modal}
          </ModalUser>
          <ModalCustomer>
            <Customer goClose={this.closeModals} />
          </ModalCustomer>
          <ModalTipoCompra>
            <TipoCompra goClose={this.closeModals} />
          </ModalTipoCompra>
          <ModalValidateNumber>
            <Number />
          </ModalValidateNumber>
          <ModalValidateToken>
            <Token />
          </ModalValidateToken>
        </div>
      </div>
    );
  }
}

ModalCustomer.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

ModalTipoCompra.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

ModalUser.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

ModalValidateNumber.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

ModalValidateToken.propTypes = {
  children: PropTypes.shape({}).isRequired,
};
