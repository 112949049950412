export const textChars = (e) => {
  const regex = /[a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ\s-]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};

export const inputTextChars = (e) => {
  const regex = /[^a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ\s-]/g;
  e.target.value = e.target.value.replace(regex, '');
};

export const numberChars = (e) => {
  const regex = /[0-9]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};
export const duiChars = (e) => {
  const regex = /[0-9-]/g;
  if (!(regex.test(e.key) || e.key === '-')) { e.preventDefault(); }
};

export const inputNumberChars = (e) => {
  const regex = /[^0-9]/g;
  e.target.value = e.target.value.replace(regex, '');
};

export const inputPhone = prefix => (e) => {
  const regex = new RegExp(`^(?!^[${prefix}]).*|\\D+`, 'g');
  e.target.value = e.target.value.replace(regex, '');
};

export const alphanumericChars = (e) => {
  const regex = /[0-9a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ\s-.,]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};

export const inputAlphanumericChars = (e) => {
  const regex = /[^0-9a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ -.,]/g;
  e.target.value = e.target.value.replace(regex, '');
};

export const emailChars = (e) => {
  const regex = /[a-zA-Z0-9_+@.-]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};

export const alphanumericEmailChars = (e) => {
  const regex = /[0-9a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ\s-.,_+@]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};

export const inputAlphanumericEmailChars = (e) => {
  const regex = /[^0-9a-zA-ZáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙüÜñÑ\s-.,_+@]/g;
  e.target.value = e.target.value.replace(regex, '');
};

export const passwordChars = (e) => {
  const regex = /\w/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};

export const nitChars = (e) => {
  const regex = /[0-9-]/g;
  if (!regex.test(e.key)) { e.preventDefault(); }
};
