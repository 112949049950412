import { Link } from '#/renderer/router';
import he from 'he';
import { inject, observer } from 'mobx-react';

const LogoutBtn = (props) => {
  function logout() {
    props.sessionStore.personLogout();
    const medalia_email = '';
    const medalia_user_login = false;
    const medalia_name = '';
    const medalia_order_id = null;
    const medalia_order_state = null;
    const medalia_order_items = null;
    localStorage.setItem('medallia_email', medalia_email);
    localStorage.setItem('medallia_user_login', medalia_user_login);
    localStorage.setItem('medallia_name', medalia_name);
    localStorage.setItem('medallia_order_id', medalia_order_id);
    localStorage.setItem('medallia_order_state', medalia_order_state);
    localStorage.setItem('medallia_order_items', medalia_order_items);
  }

  return (
    <div className="ui--logout-btn ui-log-btn active ie-fix">
      <Link to="/account">
        {props.username && <span>Hola&nbsp;</span>}
        <b>{props.username ? he.decode(props.username) : 'Mi Cuenta'}</b>
      </Link>
      <a onClick={logout}>Cerrar Sesión</a>
    </div>
  );
};

export default inject('sessionStore')(observer(LogoutBtn));
